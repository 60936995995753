<template>
  <div>
    <slot name="header"></slot>
    <form id="password-form" @submit.prevent="handleSubmit">
      <b-row>
        <b-col :lg="lgCol">
          <b-form-group>
            <label for="password"><small>New Password</small> </label>
            <b-form-input
              v-model="credCopy.newPassword"
              type="password"
              required
              id="password"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col :lg="lgCol">
          <b-form-group>
            <label for="confirmPassword"
              ><small>Confirm New Password</small>
            </label>
            <b-form-input
              required
              v-model="credCopy.confirmPassword"
              type="password"
              id="confirmPassword"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="error">
        <b-col>
          <p class="text-danger">Password doesn't match!</p>
        </b-col>
      </b-row>
      <slot name="footer"></slot>
    </form>
  </div>
</template>

<script>
  export default {
    name: 'UserFormSecurity',

    props: {
      credentials: {
        type: Object,
        required: true
      },

      design: {
        type: String,
        required: false,
        default: 'inline'
      },

      error: {
        type: String,
        required: false,
        default: ''
      }
    },

    data () {
      return {
        credCopy: { ...this.credentials }
      }
    },

    watch: {
      credentials (newVal) {
        this.credCopy = { ...newVal }
      }
    },

    computed: {
      lgCol () {
        return this.design === 'stacked' ? 12 : 6
      }
    },

    methods: {
      handleSubmit () {
        this.$emit('submitting', this.credCopy)
      }
    }
  }
</script>

<style>
</style>
